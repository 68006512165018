

<template>
    <div class="container">
        <el-tabs v-model="data" @tab-click="handleClick">
            <el-tab-pane label="中心简介" name="2-1">
               <zxjj></zxjj>
            </el-tab-pane>
            <el-tab-pane label="机构设置" name="2-2">
                <jgsz></jgsz>
            </el-tab-pane>
            <el-tab-pane label="培训设施" name="2-3">
                <pxss></pxss>
            </el-tab-pane>
            <el-tab-pane label="关于我们" name="2-4">
                <gywm></gywm>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import zxjj from '@/components/zxgk/zxjj.vue'
import jgsz from '@/components/zxgk/jgsz.vue'
import pxss from '@/components/zxgk/pxss.vue'
import gywm from '@/components/zxgk/gywm.vue'

export default {
    components:{zxjj,jgsz,pxss,gywm},
    props:['data'],
    data(){
        return {
            activeName: ''
        }
    },
    mounted() {
    },
    methods:{
        handleClick(tab, event) {
            console.log(tab.name, event);
            localStorage.setItem('isListActiveChilden',tab.name)
        }
    }
}
</script>
<style scoped lang="scss">
::v-deep .el-tabs__nav-scroll{
  display: flex;
  justify-content: center;
}
::v-deep .el-tabs__nav > div{
  font-size: 16px;
}
</style>
