<template>
    <div class="container">
        <el-tabs v-model="dataPro" @tab-click="handleClick">
            <el-tab-pane label="中心资讯" name="3-1">
                <div class="list-unstyled d-none d-sm-block">
                    <div class="media my-4 text-left cursor-pon" v-for="(item,index) in lbtNewsList" :key="item.id" @click="details(item
          )">
                        <img :src="item.image" class="mr-3" alt="" style="width: 134px;height: 100px">
                        <div class="media-body">
                            <h5 class="my-2">{{ item.title }}</h5>
                            <p>{{ item.desc }}</p>
                        </div>
                    </div>
                </div>
                <div class="list-unstyled d-block d-sm-none">
                    <div class="media2 my-4" v-for="(item,index) in lbtNewsList" @click="details(item
          )">
                        <img :src="item.image" class="img-fluid" alt="">
                        <div class="media-body">
                            <h5 class="my-2">{{ item.title }}</h5>
                            <p>{{ item.desc }}</p>
                        </div>
                    </div>

                </div>
                <el-pagination
                    @current-change="handleCurrentChange"
                    :current-page.sync="currpage"
                    :page-size="query.row"
                    layout="total, prev, pager, next"
                    :total="total">
                </el-pagination>
            </el-tab-pane>
            <el-tab-pane label="培训通知" name="3-2">
                <div v-if="lbtNewsList.length!=0">
                    <div class="list-unstyled d-none d-sm-block">
                        <div class="media my-4 text-left cursor-pon" v-for="(item,index) in lbtNewsList2" :key="item.id" @click="details(item
          )">
                            <img :src="item.image" class="mr-3" alt="" style="width: 134px;height: 100px">
                            <div class="media-body">
                                <h5 class="my-2">{{ item.title }}</h5>
                                <p>{{ item.desc }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="list-unstyled d-block d-sm-none">
                        <div class="media2 my-4" v-for="(item,index) in lbtNewsList2" @click="details(item
          )">
                            <img :src="item.image" class="img-fluid" alt="">
                            <div class="media-body">
                                <h5 class="my-2">{{ item.title }}</h5>
                                <p>{{ item.desc }}</p>
                            </div>
                        </div>
                    </div>
                    <el-pagination
                        @current-change="handleCurrentChange"
                        :current-page.sync="currpage"
                        :page-size="query.row"
                        layout="total, prev, pager, next"
                        :total="total">
                    </el-pagination>
                </div>
<!--                <el-empty v-if="lbtNewsList.length==0" description="暂无数据"></el-empty>-->
            </el-tab-pane>
            <el-tab-pane label="行业资讯" name="3-3">
                <div v-if="lbtNewsList.length!=0">
                    <div class="list-unstyled d-none d-sm-block">
                        <div class="media my-4 text-left cursor-pon" v-for="(item,index) in lbtNewsList3" :key="item.id" @click="details(item
          )">
                            <img :src="item.image" class="mr-3" alt="" style="width: 134px;height: 100px">
                            <div class="media-body">
                                <h5 class="my-2">{{ item.title }}</h5>
                                <p>{{ item.desc }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="list-unstyled d-block d-sm-none">
                        <div class="media2 my-4" v-for="(item,index) in lbtNewsList3" @click="details(item
          )">
                            <img :src="item.image" class="img-fluid" alt="" style="width: 134px;height: 100px">
                            <div class="media-body">
                                <h5 class="my-2">{{ item.title }}</h5>
                                <p>{{ item.desc }}</p>
                            </div>
                        </div>
                    </div>
                    <el-pagination
                        @current-change="handleCurrentChange"
                        :current-page.sync="currpage"
                        :page-size="query.row"
                        layout="total, prev, pager, next"
                        :total="total">
                    </el-pagination>
                </div>
<!--                <el-empty v-if="lbtNewsList.length==0" description="暂无数据"></el-empty>-->
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import pxtz from '@/components/xwdt/pxtz.vue'
import {getNewsList} from "../../utils/user";

export default {
    // components: {pxtz},
    props: ['dataPro'],
  computed:{
    jiantinglistActive(){
      return this.$store.state.listActive;
    },
    jiantingisListActiveChilden(){
      return this.$store.state.isListActiveChilden;
    }
  },
  watch:{
    jiantinglistActive(newVal,oldVal){
      console.log('00000')
      console.log(newVal)
      console.log(oldVal)

    },
    jiantingisListActiveChilden(newVal,oldVal){
      console.log('11111')
      if(newVal=='3-1'){
        this.query.type='zxzx'
        this.getZxzx()
      }
      if(newVal=='3-2'){
        this.query.type='pxtz'
        this.getZxzx2()
      }
      if(newVal=='3-3'){
        this.query.type='hyzx'
        this.getZxzx3()
      }
    }
  },
    data() {
        return {
            total:0,
            currpage:0,
            activeName: '',
            zcfgList: [],
            lbtNewsList: [],
            lbtNewsList2: [],
            lbtNewsList3: [],
            query: {
                type: 'zxzx',
                row: 8,
                page: 1
            }
        }
    },
    mounted() {
        //   中心资讯
        this.query.page=1
        this.getZxzx()



        // 旧的
        if(localStorage.getItem('isListActiveChilden')!='' && localStorage.getItem('isListActiveChilden')!=null){
            if(localStorage.getItem('isListActiveChilden')=='3-1'){
                this.query.type='zxzx'
                this.getZxzx()
            }
            if(localStorage.getItem('isListActiveChilden')=='3-2'){
                this.query.type='pxtz'
                this.getZxzx2()
            }
            if(localStorage.getItem('isListActiveChilden')=='3-3'){
                this.query.type='hyzx'
                this.getZxzx3()
            }
        }else{
            this.query.type='zxzx'
            this.getZxzx()
        }
    //     新改

    },
    methods: {
        getZxzx() {
            getNewsList(this.query).then(res => {
                if (res.code == 200) {
                    this.lbtNewsList = res.data.list
                    this.total=res.data.count
                    this.currpage=res.data.currpage
                }
            })
        },
        getZxzx2() {
            getNewsList(this.query).then(res => {
                if (res.code == 200) {
                    this.lbtNewsList2 = res.data.list
                    this.total=res.data.count
                    this.currpage=res.data.currpage
                }
            })
        },
        getZxzx3() {
            getNewsList(this.query).then(res => {
                if (res.code == 200) {
                    this.lbtNewsList3 = res.data.list
                    this.total=res.data.count
                    this.currpage=res.data.currpage
                }
            })
        },
        details(dat) {
          this.$store.commit('setShowDetail',true)
          this.$store.commit('setDetailId',dat.id)
            // this.$router.push({name: 'Details', params: {data: dat.id}})
        },
        handleClick(tab, event) {
            console.log(tab, event);
            this.total=0
            this.query.page=1
            if(tab.label=='中心资讯'){
                this.query.type='zxzx'
                this.getZxzx()
            }
            if(tab.label=='培训通知'){
                this.query.type='pxtz'
                this.getZxzx2()
            }
            if(tab.label=='行业资讯'){
                this.query.type='hyzx'
                this.getZxzx3()
            }
            localStorage.setItem('isListActiveChilden', tab.name)
            localStorage.setItem('tabName', tab.label)
        },
        handleCurrentChange(e){
            this.query.page=e
            if(localStorage.getItem('isListActiveChilden')!='' && localStorage.getItem('isListActiveChilden')!=null){
                if(localStorage.getItem('isListActiveChilden')=='3-1'){
                    this.query.type='zxzx'
                    this.getZxzx()
                }
                if(localStorage.getItem('isListActiveChilden')=='3-2'){
                    this.query.type='pxtz'
                    this.getZxzx2()
                }
                if(localStorage.getItem('isListActiveChilden')=='3-3'){
                    this.query.type='hyzx'
                    this.getZxzx3()
                }
            }else{
                this.query.type='zxzx'
                this.getZxzx()
            }
        },
    }
}
</script>
<style scoped lang="scss">
::v-deep .el-tabs__nav-scroll {
  display: flex;
  justify-content: center;
}

::v-deep .el-tabs__nav > div {
  font-size: 16px;
}

.list-unstyled {
  .media {
    img {
      max-height: 100px;
    }
  }

  .media2 {
    //display: flex;
    //flex-wrap: wrap;

    .media-body {
      flex: 0;
    }
  }

}

::v-deep .el-divider__text {
  font-size: 16px !important;
  color: red;
  font-weight: bold;
}
::v-deep .el-pagination{
    text-align: center;
}
</style>
