<template>
    <div class="container">
        <el-row class="hidden-sm-and-down">
            <el-col>
                <el-tabs v-model="data" @tab-click="handleClick"  class="el-tabs__nav-scroll1">
                    <el-tab-pane v-for="(item,index) in webList" :key="index" :label="item.label" :name="item.name">
                        <div style="text-align: left" v-if="des!='' && des!=null" v-html="des"></div>
                        <el-empty v-else description="暂无数据"></el-empty>
                    </el-tab-pane>

                </el-tabs>
            </el-col>
        </el-row>
        <el-row>
            <el-col class="hidden-sm-and-up">
                <el-tabs v-model="data" @tab-click="handleClick" :stretch="true" class="el-tabs__nav-scroll2">
                    <el-tab-pane v-for="(item,index) in webList" :key="index" :label="item.label" :name="item.name">
                        <div v-if="des!='' && des!=null" v-html="des"></div>
                        <el-empty v-else description="暂无数据"></el-empty>
                    </el-tab-pane>
                </el-tabs>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import pxtz from '@/components/xwdt/pxtz.vue'
import {getDy} from "../../utils/user";

export default {
    components: {pxtz},
    props: ['data'],
    data() {
        return {
            activeName: '',
            des: '',
            webList:[
                {
                    label:'安管人员考试培训',
                    name:'4-1'
                },{
                    label:'八大员考试培训',
                    name:'4-2'
                },{
                    label:'初、中级工程师评审',
                    name:'4-3'
                },{
                    label:'二级建造师培训',
                    name:'4-4'
                },{
                    label:'建筑企业专业承包资质及总承包资质代办',
                    name:'4-5'
                },{
                    label:'安全生产许可证新办、延期 ',
                    name:'4-6'
                },{
                    label:'学历提升',
                    name:'4-7'
                },
            ]
        }
    },
    watch: {
        data: { // $route可以用引号，也可以不用引号  监听的对象
            handler(to, from) {
                let currentid = ''
                if (to == '4-1') {
                    currentid = 5
                }
                if (to == '4-2') {
                    currentid = 6
                }
                if (to == '4-3') {
                    currentid = 7
                }
                if (to == '4-4') {
                    currentid = 8
                }
                if (to == '4-5') {
                    currentid = 9
                }
                if (to == '4-6') {
                    currentid = 10
                }
                localStorage.setItem('isListActiveChilden', to)
                getDy({
                    id: currentid
                }).then(res => {
                    if (res.code == 200) {
                        // pc轮播图
                        this.des = res.data.content
                    }
                })
            }
        }
        },
    mounted() {
        console.log('5555')
    },
    methods: {
        handleClick(tab, event) {
            let currentid = ''
            if (tab.name == '4-1') {
                currentid = 5
            }
            if (tab.name == '4-2') {
                currentid = 6
            }
            if (tab.name == '4-3') {
                currentid = 7
            }
            if (tab.name == '4-4') {
                currentid = 8
            }
            if (tab.name == '4-5') {
                currentid = 9
            }
            if (tab.name == '4-6') {
                currentid = 10
            }
            localStorage.setItem('isListActiveChilden', tab.name)
            getDy({
                id: currentid
            }).then(res => {
                if (res.code == 200) {
                    // pc轮播图
                    this.des = res.data.content
                }
            })

        }
    }
}
</script>
<style scoped lang="scss">
::v-deep .el-tabs__nav-scroll {
  display: flex;
  justify-content: center;
}

::v-deep .el-tabs__nav > div {
  font-size: 16px;
}



::v-deep .el-tabs__nav-scroll2 .el-tabs__nav-scroll {
  overflow-x: scroll !important;
}
::v-deep .el-tabs__nav-scroll1 {
    overflow-x: auto !important;
}
</style>
