import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import qs from 'qs'
import ElementUI from 'element-ui';                      // 引入element-ui
import 'element-ui/lib/theme-chalk/index.css';           // element-ui的css样式要单独引入
import 'element-ui/lib/theme-chalk/display.css';
import BaiduMap from 'vue-baidu-map';
import AMapLoader from "@amap/amap-jsapi-loader";  //引入
window._AMapSecurityConfig = {
  securityJsCode: "843f4d687731e2af563ec6022c93c770",
};
// Vue.use(BaiduMap, {
//   ak: 'sPNd3aNX5m3AbE3uaxq37kZROL34tiio'
// })
Vue.use(ElementUI)

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
