<template>
    <div class="container" style="min-height: 500px">
        <el-tabs v-model="data" @tab-click="handleClick">
            <el-tab-pane label="证书查询" name="6-1">
                <!--        <el-row :gutter="10" class="py-5">-->
                <!--          <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" v-for="(item,index) in 8">-->
                <!--            <div class="pb-3">-->
                <!--              <img src="	https://www.gsdxjd.com/uploads/image/20201201/1606808721.jpg" class="idCard-img-top" alt="...">-->
                <!--              <div class="idCard-body">-->
                <!--                <h5 class="idCard-title">培训基地监控场地</h5>-->
                <!--                <p class="idCard-text">-->
                <!--                  培训基地监控场地培训基地监控场地培训基地监控场地培训基地监控场地培训基地监控场地培训基地监控场地</p>-->
                <!--                <el-button class="mt-3" type="primary" @click="details(item)">查看详情</el-button>-->
                <!--              </div>-->
                <!--            </div>-->
                <!--          </el-col>-->

                <!--        </el-row>-->
                <!--        <el-tag class="mr-2" type="success">安全培训证书查询</el-tag>-->
                <!--        <el-tag class="mr-2" type="info">安全培训证书查询</el-tag>-->
                <!--        <el-tag class="mr-2" type="warning">安全培训证书查询</el-tag>-->
                <!--        <el-tag class="mr-2" type="danger">安全培训证书查询</el-tag>-->
                <div class="d-flex flex-wrap w-100 hidden-sm-and-down">
                    <h3 class="bg-info  p-5 rounded text-black text-black  text-center m-2" style="width: 46%;cursor: pointer" v-for="item in 1" @click="cert">
                        安全培训证书查询</h3>
                </div>
              <div class="hidden-sm-and-up w-75">
                <h3 class="bg-info  p-4 rounded text-black text-black  text-center m-2 mx-auto" style="cursor: pointer;font-size: 24px;width: 100%" v-for="item in 1" @click="cert">
                  安全培训证书查询</h3>
              </div>

            </el-tab-pane>
            <el-tab-pane label="在线报名" name="6-2">
                <el-form :model="form" :rules="rules" ref="form">
                    <el-form-item label="姓名" prop="name">
                        <el-input v-model="form.name"></el-input>
                    </el-form-item>
                    <el-form-item label="性别">
                        <el-radio-group class="text-left" v-model="form.gender">
                            <el-radio :label="1">男</el-radio>
                            <el-radio :label="2">女</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="身份证号" prop="idCard">
                        <el-input v-model="form.idCard" placeholder="请输入身份证号"/>
                    </el-form-item>
                    <el-form-item label="手机号码" prop="mobile">
                        <el-input v-model="form.mobile"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="handleSubmit('ruleForm')">提交</el-button>
                    </el-form-item>
                </el-form>

            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import pxtz from '@/components/xwdt/pxtz.vue'
import {getlbt, zxbm} from "../../utils/user";

export default {
    components: {pxtz},
    props: ['data'],
    data() {
        return {
            // PC轮播图
            pcLbt: [],
            activeName: '',
            form: {
                mobile: '',
                name: '',
                idCard: '',
                gender: 1,   //性别 0女 1男
            },
            rules: {
                name: [
                    {required: true, message: '请输入姓名', trigger: 'blur'}
                ],
                // 手机号码校验规则
                mobile: [
                    {required: true, message: '请输入手机号码', trigger: 'blur'},
                    {validator: this.checkPhoneNum, trigger: 'blur'},
                ],
                //身份证号校验规则
                idCard: [
                    {required: true, message: "身份证号不能为空", trigger: "blur"},
                    {validator: this.isCnNewID, trigger: 'blur'}
                ],
            }
        }
    },
    mounted() {

    },
    methods: {
        cert(){
            // window.location.href = 'https://edu.gsdxjd.com/';
            window.open('https://edu.gsdxjd.com/', '_blank');
        },
        reset() {
            this.form = {
                mobile: '',
                name: '',
                idCard: '',
                gender: 1,   //性别 0女 1男
            }
        },
        handleSubmit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    zxbm(this.form).then(res => {
                        console.log(res)
                        if (res.code == 200) {
                            this.$message({
                                message: '恭喜你，在线报名成功',
                                type: 'success'
                            });
                            this.reset()
                        }
                    })
                } else {
                    return false;
                }
            });
        },

        details(dat) {
          this.$store.commit('setShowDetail',true)
          this.$store.commit('setDetailId',dat.id)
            // this.$router.push({name: 'Details', params: {data: dat.id}})
        },
        handleClick(tab, event) {
            console.log(tab, event);
            localStorage.setItem('isListActiveChilden', tab.name)
        },
        // 银行卡号校验函数
        checkCardNum(rule, value, callback) {
            var reg = /^([1-9]{1})(\d{14}|\d{18})$/;
            if (reg.test(value)) {
                callback();
            } else {
                callback(new Error('银行卡号格式不正确'));
            }
        },
        //身份证号校验
        //身份证校验
        isCnNewID(rule, value, callback) {
            var arrExp = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];//加权因子
            var arrValid = [1, 0, "X", 9, 8, 7, 6, 5, 4, 3, 2];//校验码
            if (/^\d{17}\d|x$/i.test(value)) {
                var sum = 0, idx;
                for (var i = 0; i < value.length - 1; i++) {
                    // 对前17位数字与权值乘积求和
                    sum += parseInt(value.substr(i, 1), 10) * arrExp[i];
                }
                // 计算模（固定算法）
                idx = sum % 11;
                // 检验第18为是否与校验码相等
                if (arrValid[idx] == value.substr(17, 1).toUpperCase()) {
                    callback()
                } else {
                    callback("身份证格式有误")
                }
            } else {
                callback("身份证格式有误")
            }
        },
        // 手机号码校验函数
        checkPhoneNum(rule, value, callback) {
            var reg = /^1[3456789]\d{9}$/;
            if (reg.test(value)) {
                callback();
            } else {
                callback(new Error('手机号码格式不正确'));
            }
        }
    }
}
</script>
<style scoped lang="scss">
.bg-info {
  //background: linear-gradient(to right, #9fe1fa, #f4edc9);
  background: radial-gradient(circle at 10% 20%, rgb(239, 246, 249) 0%, rgb(206, 239, 253) 90%)

}

::v-deep .el-tabs__nav-scroll {
  display: flex;
  justify-content: center;
}

::v-deep .el-tabs__nav > div {
  font-size: 16px;
}

::v-deep .el-form-item__content {
  display: flex;
  align-items: center;
}

::v-deep .el-form-item {
  margin-bottom: 22px;
  display: flex;
  flex-direction: unset;
  min-width: 280px;
}

::v-deep .el-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

::v-deep .el-tab-pane {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
::v-deep .el-form-item__label {
    font-size: 18px!important;
}
</style>
