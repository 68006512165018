import serviceAxios from "./index";

export const getlbt = (params) => {
    return serviceAxios({
        url: "/index/config/getBanner",
        method: "GET",
        params,
    });
};

// 新闻
export const getNewsList = (params) => {
    return serviceAxios({
        url: "/index/index/getNewsList",
        method: "GET",
        params,
    });
};
// 新闻详情
export const getNewsDetailList = (params) => {
    return serviceAxios({
        url: "/index/index/getNewsDetail",
        method: "GET",
        params,
    });
};
// 单页内容
export const getDy = (params) => {
    return serviceAxios({
        url: "/index/index/getSinglePage",
        method: "GET",
        params,
    });
};
export const login = (data) => {
    return serviceAxios({
        url: "/api/user/login",
        method: "post",
        data,
    });
};

export const zxbm = (data) => {
    return serviceAxios({
        url: "/index/index/design",
        method: "post",
        data,
    });
};
// 获取底部导航连接
export const getBottomLink = (params) => {
    return serviceAxios({
        url: "/index/index/getFriendlink",
        method: "GET",
        params,
    });
};
