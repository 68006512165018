<template>
  <div>
    <div class="container">

      <div class="row w-100 mt-2">
        <div class="col-md-12 col-lg-8">
          <el-carousel class="d-none d-md-block" indicator-position="outside" height="400px">
            <el-carousel-item v-for="item in pcLbt" :key="item.id">
              <div class="lbt-list-item">
                <!--                  <img class="img-fluid"  :src="item.url">-->
                <el-image
                    style="width: 100%; height: 400px"
                    :src="item.image"
                >
                </el-image>
                <div class="title">{{ item.second_title }}</div>
              </div>
            </el-carousel-item>
          </el-carousel>

          <el-carousel class="d-sm-none" indicator-position="outside" height="250px">
            <el-carousel-item v-for="item in xpLbt" :key="item.id">
              <div class="lbt-list-item">
                <!--                  <img class="img-fluid"  :src="item.url">-->
                <el-image
                    style="width: 100%; height: 250px"
                    :src="item.image"
                >
                </el-image>
                <div class="title">{{ item.second_title }}</div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>

        <div class=" col-lg-4 home-main-right">
          <div class="card" style="border: none">

            <div class="card-main d-flex">
              <div class="card-news-head d-flex">
                <div class="card-news-head-list cursor-pon" v-for="(item,index) in mainList" :key="index"
                     :class="item.id==mainListActive?'main-active-index':''"
                     @click="mouseenterTab(item)">
                  {{ item.title }}

                </div>
              </div>
            </div>
            <div class="card-news-list-main">
              <div class="card-news-list" v-for="(ite2,ind2) in lbtNewsList" :key="ite2.id"
                   @click="details(ite2)">
                <div class="card-news-list-left w-75">
                  {{ ite2.title }}
                </div>
                <span>{{ ite2.create_time | filtersData }}</span>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>


    <div class="main-center">
      <!--    中心图片-->
      <div class="center-img my-4">
        <img src="@/assets/dxjd.jpg" class="img-fluid"
             alt="Responsive image">
      </div>
      <!--      &lt;!&ndash;政策法规&ndash;&gt;-->
      <!--      <div class=" zcfg">-->
      <!--        <div class="container zcfg-main">-->
      <!--          <h4 class="py-3">新闻动态</h4>-->
      <!--          <h4>News Updates</h4>-->
      <!--        </div>-->
      <!--      </div>-->
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-card class="box-card" shadow="hover">
            <div slot="header" class="clearfix">
              <span class="news-main-title float-left">行业资讯</span>
<!--              <el-button style="float: right; padding: 3px 0" type="text" @click="newsmore('行业资讯')">更多-->
<!--                >-->
<!--              </el-button>-->
            </div>
            <div class="text item">
              <div class="card-news">
                <div class="card-news-list" v-for="(item,index) in mostNewsList" @click="details(item)">
                  <div class="card-news-list-left w-75">
                    <!--                                        <span class="hidden-sm-and-down yuandian"></span>-->
                    {{ item.title }}
                  </div>
                  <span>{{ item.update_time | filtersData }}</span>
                </div>
              </div>
            </div>
          </el-card>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <el-card class="box-card hidden-sm-and-down">
            <div slot="header" class="clearfix">
              <span class="news-main-title float-left">培训通知</span>
<!--              <el-button style="float: right; padding: 3px 0" type="text" @click="peiXunNewsMore('培训通知')">更多-->
<!--                >-->
<!--              </el-button>-->
            </div>
            <div class="text item">
              <div class="card-news">
                <div class="card-news-list" v-for="(item,index) in ZxzxPxtzList" @click="details(item)">
                  <div class="card-news-list-left w-75">
                    <!--                                        <span class="yuandian"></span>-->
                    {{ item.title }}
                  </div>
                  <span>{{ item.update_time | filtersData }}</span>
                </div>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>


    <div class="zcfg-main-top hidden-md-and-down">
      <!--培训设施-->
      <div class=" zcfg">
        <div class="container zcfg-main">
          <h4 class="py-2">培训设施</h4>
          <h4>Training Facilities</h4>
        </div>
      </div>
      <el-carousel :interval="4000" type="card" height="300px">
        <el-carousel-item v-for="(item,index) in pxssList" :key="index">
          <img class="pxss-img" :src="item.image" alt=""
               srcset="">
        </el-carousel-item>

      </el-carousel>
    </div>
    <div class="zcfg-main-top hidden-md-and-up ">
      <!--培训设施-->
      <div class=" zcfg">
        <div class="container zcfg-main">
          <h4 class="py-2">培训设施</h4>
          <h4>Training Facilities</h4>
        </div>
      </div>
      <el-carousel :interval="4000" type="card" height="130px">
        <el-carousel-item v-for="(item,index) in pxssList" :key="index">
          <img class="pxss-img" :src="item.image" alt=""
               srcset="">
        </el-carousel-item>

      </el-carousel>
    </div>
    <div class=" zcfg">
      <div class="container zcfg-main">
        <h4 class="py-2">政策法规</h4>
        <h4>Policies And Regulations</h4>
      </div>
    </div>
    <div class="container">
      <el-row :gutter="10" class="py-5">
        <el-col v-for="(item,index) in zcfgList" :key="index" v-if="item.is_show==1"
                class="ywfwlist p-2  bg-white" :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
          <div class="pb-3">
            <img style="height: 200px" :src="item.image" class="card-img-top" alt="...">
            <div class="card-body">
              <h5 class="card-title">{{ item.title }}</h5>
              <p class="card-text">{{ item.desc }}</p>
              <el-button class="mt-3" type="primary" @click="details(item)">查看详情</el-button>
            </div>
          </div>
        </el-col>
      </el-row>

    </div>


  </div>

</template>
<script>
import {getlbt, getNewsList} from "../utils/user";

export default {
  filters: {
    filtersData(data) {
      let de = data.replace(" ", ":").replace(/\:/g, "-").split("-")
      return de[0] + '-' + de[1] + '-' + de[2]
    }
  },
  data() {
    return {
      pcLbt: [],
      xpLbt: [],
      //   行业资讯
      ZxzxPxtzList: [],
      mostNewsList: [],
      // 政策法规
      zcfgList: [],
      pxtzList: [],
      pxssList: [],
      query: {
        type: 'zxzx',
        row: 8,
        page: 1
      },
      mainList: [
        {
          id: 1,
          title: '中心资讯',
          py: 'zxzx'
        }, {
          id: 2,
          title: '培训通知',
          py: 'pxtz'
        }, {
          id: 3,
          title: '行业资讯',
          py: 'hyzx'
        },
      ],
      mainListActive: 1,
      //   轮播图右新闻列表
      lbtNewsList: [],
      //     培训设施
      pxss: [
        {
          id: 0,
          src: '@/assets/lunbo1.jpeg'
        }, {
          id: 2,
          src: '@/assets/lunbo2.jpg'
        }, {
          id: 3,
          src: '@/assets/lunbo3.jpeg'
        }
      ],
      hyzxOps: [3, 3 - 3],
      pxtzOps: [3, 3 - 2],

    }
  },
  mounted() {
    //     PC获取轮播图
    this.getlbtlist()
    //     手机获取轮播图
    this.getlbtmobilelist()
    //   中心资讯
    this.getZxzx()
    //     最新新闻
    this.getNews()
    //     培训通知
    this.getPxtz()
    this.getZxzxPxtz()
    //     培训设施
    this.getPxss()
    // 政策法规
    this.getZcfg()
    // window.addEventListener('scroll', this.windowScroll) //监听页面滚动

  },
  methods: {
    // 监听页面滚动 获取滚动条位置
    windowScroll() {
      let scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop
      console.log('子组件')
      console.log(scrollTop)
      this.$store.commit('setScreenHeight', scrollTop)
    },
    getlbtlist() {
      this.pcLbt = []
      getlbt({
        platform: 'web',
        position: 'web_home'
      }).then(res => {
        if (res.code == 200) {
          // pc轮播图
          this.pcLbt = res.data
        }
      })
    },
    // 最新新闻
    getNews() {
      this.mostNewsList = []
      getNewsList({
        type: 'hyzx',
        row: 10,
        page: 1
      }).then(res => {
        if (res.code == 200) {
          this.mostNewsList = res.data.list

        }
      })
    },
    // 政策法规
    getZcfg() {
      this.zcfgList = []
      getNewsList({
        type: 'zcfg',
        row: 8,
        page: 1
      }).then(res => {
        if (res.code == 200) {
          this.zcfgList = res.data.list

        }
      })
    },
    getPxtz() {
      this.mostNewsList = []
      getNewsList({
        type: 'pxtz',
        row: 8,
        page: 1
      }).then(res => {
        if (res.code == 200) {
          this.pxtzList = res.data.list


        }
      })
    },
    getZxzxPxtz() {
      this.ZxzxPxtzList = []
      getNewsList({
        type: 'pxtz',
        row: 5,
        page: 1
      }).then(res => {
        if (res.code == 200) {
          this.ZxzxPxtzList = res.data.list
        }
      })
    },
    getPxss() {
      this.pxssList = []
      getNewsList({
        type: 'pxss',
        row: 8,
        page: 1
      }).then(res => {
        if (res.code == 200) {
          this.pxssList = res.data.list


        }
      })
    },
    getlbtmobilelist() {
      this.xpLbt = []
      getlbt({
        platform: 'mobile',
        position: 'min_home'
      }).then(res => {
        if (res.code == 200) {
          // pc轮播图
          this.xpLbt = res.data
        }
      })
    },
    getZxzx() {
      getNewsList(this.query).then(res => {
        if (res.code == 200) {
          this.lbtNewsList = res.data.list
        }
      })
    },
    //    最新新闻
    mouseenterTab(e) {
      this.mainListActive = e.id
      this.lbtNewsList = []
      this.query.type = e.py
      this.getZxzx()
    },
    newsmore(des) {
      localStorage.setItem('newsType', des)
      // this.$router.push('/more')
      localStorage.setItem('listActive', 3)
      localStorage.setItem('isListActiveChilden', '3-3')
      this.$emit('pathClick', '3-3', this.hyzxOps)
      // this.$router.push({name: 'More', params: {data: des}})

    },
    peiXunNewsMore(des) {
      localStorage.setItem('newsType', des)
      // this.$router.push('/more')
      localStorage.setItem('listActive', 3)
      localStorage.setItem('isListActiveChilden', '3-2')
      this.$emit('pathClick', '3-2', this.pxtzOps)
      // this.$router.push({name: 'More', params: {data: des}})

    },
    details(dat) {
      this.$store.commit('setShowDetail', true)
      this.$store.commit('setDetailId', dat.id)
      // this.$router.push({name: 'Details', params: {data: dat.id}})
    },
    mouseLeave() {
      this.mainListActive = this.mainListActive

    }
  }
}
</script>
<style lang="scss" scoped>
.home-nav-main {
  background: url("https://zjt.gansu.gov.cn/zjt/xhtml/images/nav_bg.png");
}

.card-title {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.carousel {
  //max-width: 740px;
  //height: 400px;
}

.carousel2 {
  width: 100%;
  position: relative !important;
  height: auto;

}

.carousel-inner {
  //height: 400px;
}

.carousel-inner2 {
  height: auto;
}

.carousel-item {
  //height: 400px;
}

.carousel-item2 {
  height: auto;
}

.carousel-inner img {
  width: 100%;
  height: 100%;
}

.body_head {
  min-height: 699px;
  height: 100%;
  width: 100%;
  background: url("../assets/bg.jpg") no-repeat 100% 100%;
  background-size: cover;
}

.nav-item {
  font-size: 20px;

  i {
    margin-right: 5px;
  }
}

.nav-item:hover {
  background: #ffffff;
  color: #d10206 !important;
  font-weight: bold;
  cursor: pointer;

  a {
    color: #ffffff;

  }
}

.home-lbt {
  max-width: 1150px;
  max-height: 510px;
  overflow: hidden;
}

.home_nav_bg {
  width: 100%;
  height: 180px;
  background: url("https://zjt.gansu.gov.cn/zjt/xhtml/images/header.png");
  background: url("../assets/b1.jpg");

}

.navbar_bg {
  width: 100%;
  background: url("https://zjt.gansu.gov.cn/zjt/xhtml/images/nav_bg.png") no-repeat;
}

.custom-navbar {
  background: red;
}

.head_right {
  position: relative;
}

.col-sm-8 {
  background: pink;
}

.col-sm-4 {
  background: antiquewhite;
}

.experts-li {
  width: 350px;
  height: 200px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #eaeaea;
  transition: all 0.8s;
  cursor: pointer;
}

.experts-li:hover {
  box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.15);
  transform: scale(1.05);
  cursor: pointer;
  transition: all 0.4s;
}

/* div#collapsibleNavbar {
    position: absolute;
    top: 100%;
    background: pink;
    width: 100%;
    left: 0;
} */
.main {
  height: 100%;
  box-sizing: content-box;
}

.bottom_list {
  min-height: 170px;
}

::v-deep .row, .col-sm-6 {
  margin: 0;
  padding: 0;
}

img-hover:hover {
  transform: scale(1.2);
  /*这里是放大的倍数*/
  transition: all 0.2s ease-in-out;
  /*这里是放大过程的时间和方式*/
}

.touxiang {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
}

.container {
  display: flex;
  padding: 0 !important;

  .home-main-right {
    //width: 430px;
    padding: 0 5px !important;
  }
}

li {
  list-style: none;
}

.navbar, .nav-link {
  padding: 0;
}

.navbar-nav {
  background: url("https://zjt.gansu.gov.cn/zjt/xhtml/images/nav_bg.png");
}

navbar-nav.row.w-100 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
}

li {

}

.main-center {
  margin: 0 auto;
  max-width: 1140px;
  box-sizing: border-box;
}

.nav-item {
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff !important;

  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}

.yesActive {
  border-top: 3px solid red;
  background: #ffffff;
  color: #d10206 !important;
  font-weight: bold;
}

.noActive {
  color: #000;
}

/* 最外层 */
.content {
  display: flex;
  flex-direction: row;
}

/* END */

/* 每个菜单的样式(宽度这里调,子菜单宽度都听这个) */
.items {
  width: 130px;
  color: #fff;
}


.dropdown-menu {
  float: none;
  position: absolute;
  top: 56px;
  width: 100%;
  text-align: center;
  z-index: 9999;
  background: #ffffff;
  color: blue;

  .dropdown-item {
    color: #0f6674;
  }
}

.btn-group {
  display: flex;
  align-items: center;
}

.card-header {
  display: flex;
  text-align: left;

  > div {
    margin-left: 10px;
  }

}

.news-main {
  margin: 20px auto;
  justify-content: space-between;

  .box-card {
    //width: 49%;
  }
}

.news-main-title {
  font-size: 20px;
  color: #e20805;
  position: relative;
  font-weight: 700;
}

.card-main {
  .card-news-head {
    margin-left: 0;
    width: 100%;

    .card-news-head-list {
      width: 33%;
      background: #eee;
      color: #333;
      height: 40px;
      line-height: 40px;

      //&:hover {
      //  background: #409EFF;
      //  cursor: pointer;
      //  color: #ffffff;
      //}

      .card-news-list-main {
        color: #000000 !important;
      }

    }
  }
}

.card-news-list {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 4px 0;
  box-sizing: border-box;

  .card-news-list-left {
    overflow: hidden;
    text-overflow: ellipsis;
    border-bottom: 1px solid #f5f5f5;
    line-height: 30px;
    height: 30px;
    padding: 4px 6px;
    box-sizing: content-box;
    text-align: left;
    white-space: nowrap;

    .yuandian {
      position: absolute;
      top: 50%;
      left: 2px;
      display: inline-block;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: red;
    }

  }

  :last-child {
    border: none;
  }

  span {
    color: #666;
    font-size: 14px;
  }

  &:hover {
    color: #409EFF;
    cursor: pointer;

    span {
      color: #409EFF;
    }
  }
}

.card-news {
  //height: 349px;

  .card-news-list {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .card-news-list-left {
      overflow: hidden;
      text-overflow: ellipsis;
      border-bottom: 1px solid #f5f5f5;
      line-height: 30px;
      height: 30px;
      padding: 4px 6px;
      box-sizing: content-box;
      text-align: left;
      white-space: nowrap;

      .yuandian {
        position: absolute;
        top: 46%;
        left: 2px;
        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: red;
      }

    }

    :last-child {
      border: none;
    }

    span {
      color: #666;
      font-size: 14px;
    }

    &:hover {
      color: #409EFF;
      cursor: pointer;

      span {
        color: #409EFF;
      }
    }
  }

}

.zcfg {
  //height: 100px;
  background: #f9f9f9;
  font-size: 36px;
  margin: 20px 0;

  .set-h4 {
    font-size: 20px;
    color: #e20805;
    font-weight: 700;
  }

  .zcfg-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #6b5436;
  }
}

.zcfg-main-top {
  max-width: 1140px;
  margin: 20px auto;

  .pxss-img {
    width: 100%;
  }

  .py-2 {
    //color: #409EFF;
  }
}

.col-lg-4, .col-lg-2, .col-lg-8 {
  padding: 0 !important;
}

.ywfw {
  max-width: 1140px;
  margin: 0 auto;

  .ywfwlist {
    .ywfwlist-img {
      height: 150px;
      border-radius: 10px;
      overflow: hidden;
      padding: 0 !important;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

::v-deep .el-tabs__nav {
  > div {
    font-size: 16px !important;

  }
}

::v-deep .el-tabs__nav-wrap {
  padding: 0;
}

::v-deep .el-tabs__item.is-active {
  background: #409EFF !important;
  color: #ffffff !important;
}

.el-card__header {
  text-align: left !important;
}

::v-deep .el-card__body {
  padding: 0 !important;
}

.main-active-index {
  background: #409EFF !important;
  color: #ffffff !important;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}

.lbt-list-item {
  height: 100%;

  img {
    max-height: 400px;
  }

  .title {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    background: #000000;
    color: white;
  }
}
</style>
