<script >

</script>

<template>
    <div class="container">
        <div v-html="des"></div>
    </div>
</template>
<script>
import {getDy, getlbt} from "../../utils/user";

export default {
    data(){
        return {
            des:''
        }
    },
    mounted() {
        getDy({
            id:4
        }).then(res => {
            if (res.code == 200) {
                // pc轮播图
                this.des=res.data.content
            }
        })
    }
}
</script>
<style scoped lang="scss">
.actile{
  text-indent: 30px;
}
</style>
