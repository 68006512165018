<template>
    <div class="container">
        <!--    <el-tabs v-model="data" @tab-click="handleClick">-->
        <!--      <el-tab-pane label="全部" name="5-1">-->
        <!--        -->
        <!--      </el-tab-pane>-->
        <!--        <el-tab-pane label="资质类" name="5-2">-->
        <!--            <el-empty description="资质类暂无数据"></el-empty>-->
        <!--        </el-tab-pane>-->
        <!--        <el-tab-pane label="安全生产类" name="5-3">-->
        <!--            <el-empty description="安全生产类暂无数据"></el-empty>-->
        <!--        </el-tab-pane>-->
        <!--    </el-tabs>-->
        <el-row :gutter="10" class="py-5">
            <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" v-for="(item,index) in zcfgList" :key="index">
                <div class="pb-3">
                    <img style="height: 210px" :src="item.image" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h5 class="card-title">{{ item.title }}</h5>
                        <p class="card-text">{{ item.desc }}</p>
                        <el-button class="mt-3" type="primary" @click="details(item)">查看详情</el-button>
                    </div>
                </div>
            </el-col>

        </el-row>
        <el-pagination
                @current-change="handleCurrentChange"
                :current-page.sync="currpage"
                :page-size="query.row"
                layout="total, prev, pager, next"
                :total="total">
        </el-pagination>
    </div>
</template>
<script>

import {getNewsList} from "../../utils/user";

export default {
    props: ['data'],
    data() {
        return {
            total: 0,
            currpage: 0,
            activeName: '',
            query: {
                type: 'zcfg',
                row: 8,
                page: 1
            },
            zcfgList: []
        }
    },
    mounted() {
        this.total=0
            this.currpage=0
        this.getZcfg()
    },
    methods: {
        handleCurrentChange(e) {
            this.query.page = e
            this.getZcfg()
        },
        // 政策法规
        getZcfg() {
            this.zcfgList = []
            getNewsList(this.query).then(res => {
                if (res.code == 200) {
                    this.zcfgList = res.data.list
                    this.total = res.data.count
                    this.currpage = res.data.currpage

                }
            })
        },
        qq() {
            window.location.href = 'http://wpa.qq.com/msgrd?v=3&uin=1129861050'
        },
        handleClick(tab, event) {
            console.log(tab, event);
            localStorage.setItem('isListActiveChilden', tab.name)
        },
        details(dat) {
          this.$store.commit('setShowDetail',true)
          this.$store.commit('setDetailId',dat.id)
            // this.$router.push({name: 'Details', params: {data: dat.id}})
        }
    }
}
</script>
<style scoped lang="scss">
.card-title {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

::v-deep .el-tabs__nav-scroll {
  display: flex;
  justify-content: center;
}

::v-deep .el-tabs__nav > div {
  font-size: 16px;
}
::v-deep .el-pagination{
    text-align: center;
}
</style>
