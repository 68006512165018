<template>
    <div class="container">
        <!--  <div class="card" :xs="12" :sm="12" :md="6" :lg="6" >-->
        <!--    <img src="@/assets/zhuanye.jpg" class="card-img-top" alt="...">-->
        <!--    <div class="card-body">-->
        <!--      <h5 class="card-title">Card title</h5>-->
        <!--      <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>-->
        <!--      <a href="#" class="btn btn-primary">Go somewhere</a>-->
        <!--    </div>-->
        <!--  </div>-->
        <!--  <div class="card" :xs="12" :sm="12" :md="6" :lg="6" >-->
        <!--    <img src="@/assets/zhuanye.jpg" class="card-img-top" alt="...">-->
        <!--    <div class="card-body">-->
        <!--      <h5 class="card-title">Card title</h5>-->
        <!--      <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>-->
        <!--      <a href="#" class="btn btn-primary">Go somewhere</a>-->
        <!--    </div>-->
        <!--  </div>-->
        <div v-if="isDetail==false">
            <el-row :gutter="10" class="py-5">
                <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" v-for="(item,index) in pxssList" :key="index">
                    <div class="pb-3">
                        <img style="height: 210px" :src="item.image" class="card-img-top" alt="...">
                        <div class="card-body">
                            <h5 class="card-title">{{ item.title }}</h5>
                            <p class="card-text">{{ item.desc }}</p>
                            <el-button class="mt-3" type="primary" @click="details(item)">查看详情</el-button>
                        </div>
                    </div>
                </el-col>

            </el-row>
        </div>
        <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="currpage"
            :page-size="query.row"
            layout="total, prev, pager, next"
            :total="total">
        </el-pagination>

    </div>
</template>
<script>
import {getNewsList} from "../../utils/user";

export default {
    data() {
        return {
            isDetail: false,
            total:0,
            currpage:0,
            query: {
                type: 'pxss',
                row: 8,
                page: 1
            },
            pxssList: []
        }
    },
    mounted() {
        this.getPxss()
    },
    methods: {
        handleCurrentChange(e){
            this.query.page=e
           this.getPxss()
        },
        getPxss() {
            this.pxssList = []
            getNewsList(this.query).then(res => {
                if (res.code == 200) {
                    this.pxssList = res.data.list
                    this.total = res.data.count
                    this.currpage=res.data.currpage

                }
            })
        },
        details(dat) {
          this.$store.commit('setShowDetail',true)
          this.$store.commit('setDetailId',dat.id)
            // this.$router.push({name: 'Details', params: {data: dat.id}})
        }
    }
}
</script>
<style scoped lang="scss">
.card-title {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
::v-deep .el-pagination{
    text-align: center;
}
</style>
