<template>
  <div class="home">
    <div class="home-nav-bg d-none d-sm-block"></div>

    <div class="home-nav-main">
      <div class="container">
        <nav class="navbar navbar-expand-md bg-dark navbar-dark">
          <!-- Brand -->
          <!--                    <a class="navbar-brand" href="#">LOGO</a>-->
          <a class="navbar-brand d-block  d-sm-none" href="#">
            <img style="width: 40px;height: 40px" src="@/assets/logo.png" alt="" srcset="">
          </a>
          <!-- Toggler/collapsibe Button -->
          <button id="zkbtn" class="navbar-toggler" type="button" data-toggle="collapse"
                  data-target="#collapsibleNavbar">
            <span class="navbar-toggler-icon"></span>
          </button>
          <!-- Navbar links -->
          <div class="collapse navbar-collapse w-100" id="collapsibleNavbar">
            <el-menu default-active="1" unique-opened  ref="menu"
                     class="w-100 el-menu-demo d-flex flex-column flex-md-row menu-set-class font-weight-bold"
                     text-color="#ffffff" active-text-color="red" mode="horizontal" menu-trigger="click"
                     @select="handleSelect">
              <el-menu-item class="col-sm-2" index="1" :class="(isJiHuo==true && isActive==1)?'jihuo':'no'">
                首页
              </el-menu-item>
              <el-submenu class="col-sm-2" index="2"
                          :style="isActive==2?'color:red;background:#ffffff':''">
                <template slot="title">
                  <span :style="isActive==2?'color:red':''">中心概况</span>
                </template>
                <el-menu-item
                    :style="isActiveChilden=='2-1'?'background:#3a6cc1e8;color: white':'background:#ffffff'"
                    style="color: #000000;" index="2-1">中心简介
                </el-menu-item>
                <el-menu-item
                    :style="isActiveChilden=='2-2'?'background:#3a6cc1e8;color: white':'background:#ffffff'"
                    style="color: #000000;" index="2-2">机构设置
                </el-menu-item>
                <el-menu-item
                    :style="isActiveChilden=='2-3'?'background:#3a6cc1e8;color: white':'background:#ffffff'"
                    style="color: #000000;" index="2-3">培训设施
                </el-menu-item>
                <el-menu-item
                    :style="isActiveChilden=='2-4'?'background:#3a6cc1e8;color: white':'background:#ffffff'"
                    style="color: #000000;" index="2-4">关于我们
                </el-menu-item>
              </el-submenu>
              <el-submenu class="col-sm-2" index="3"
                          :style="isActive==3?'color:red;background:#ffffff':''">
                <template slot="title">
                  <span :style="isActive==3?'color:red':''">新闻动态</span>
                </template>
                <el-menu-item
                    :style="isActiveChilden=='3-1'?'background:#3a6cc1e8;color: white':'background:#ffffff'"
                    style="color: #000000;" index="3-1">中心咨询
                </el-menu-item>
                <el-menu-item
                    :style="isActiveChilden=='3-2'?'background:#3a6cc1e8;color: white':'background:#ffffff'"
                    style="color: #000000;" index="3-2">培训通知
                </el-menu-item>
                <el-menu-item
                    :style="isActiveChilden=='3-3'?'background:#3a6cc1e8;color: white':'background:#ffffff'"
                    style="color: #000000;" index="3-3">行业资讯
                </el-menu-item>
              </el-submenu>
              <el-submenu class="col-sm-2" index="4"
                          :style="isActive==4?'color:red;background:#ffffff':''">
                <template slot="title">
                  <span :style="isActive==4?'color:red':''">业务范围</span>
                </template>
                <el-menu-item
                    :style="isActiveChilden=='4-1'?'background:#3a6cc1e8;color: white':'background:#ffffff'"
                    style="color: #000000;" index="4-1">安管人员考试培训
                </el-menu-item>
                <el-menu-item
                    :style="isActiveChilden=='4-2'?'background:#3a6cc1e8;color: white':'background:#ffffff'"
                    style="color: #000000;" index="4-2">八大员考试培训
                </el-menu-item>
                <el-menu-item
                    :style="isActiveChilden=='4-3'?'background:#3a6cc1e8;color: white':'background:#ffffff'"
                    style="color: #000000;" index="4-3">初、中级工程师评审
                </el-menu-item>
                <el-menu-item
                    :style="isActiveChilden=='4-4'?'background:#3a6cc1e8;color: white':'background:#ffffff'"
                    style="color: #000000;" index="4-4">二级建造师培训
                </el-menu-item>
                <el-menu-item
                    :style="isActiveChilden=='4-5'?'background:#3a6cc1e8;color: white':'background:#ffffff'"
                    style="color: #000000;" index="4-5">
                  建筑企业专业承包资质及总承包资质代办
                </el-menu-item>
                <el-menu-item
                    :style="isActiveChilden=='4-6'?'background:#3a6cc1e8;color: white':'background:#ffffff'"
                    style="color: #000000;" index="4-6">
                  安全生产许可证新办、延期
                </el-menu-item>
                <el-menu-item
                    :style="isActiveChilden=='4-7'?'background:#3a6cc1e8;color: white':'background:#ffffff'"
                    style="color: #000000;" index="4-7">
                  学历提升
                </el-menu-item>

              </el-submenu>
              <el-menu-item class="col-sm-2" index="5"
                            :style="isActive==5?'color:red;background:#ffffff':''">政策法规
              </el-menu-item>
              <!--                            <el-submenu class="col-sm-2" index="5"-->
              <!--                                        :style="isActive==5?'color:red;background:#ffffff':''">-->
              <!--                                <template slot="title">-->
              <!--                                    <span :style="isActive==5?'color:red':''">政策法规</span>-->
              <!--                                </template>-->
              <!--                                <el-menu-item-->
              <!--                                        :style="isActiveChilden=='5-1'?'background:#3a6cc1e8;color: white':'background:#ffffff'"-->
              <!--                                        style="color: #000000;" index="5-1">全部-->
              <!--                                </el-menu-item>-->
              <!--&lt;!&ndash;                                <el-menu-item&ndash;&gt;-->
              <!--&lt;!&ndash;                                        :style="isActiveChilden=='5-2'?'background:#3a6cc1e8;color: white':'background:#ffffff'"&ndash;&gt;-->
              <!--&lt;!&ndash;                                        style="color: #000000;" index="5-2">资质类&ndash;&gt;-->
              <!--&lt;!&ndash;                                </el-menu-item>&ndash;&gt;-->
              <!--&lt;!&ndash;                                <el-menu-item&ndash;&gt;-->
              <!--&lt;!&ndash;                                        :style="isActiveChilden=='5-3'?'background:#3a6cc1e8;color: white':'background:#ffffff'"&ndash;&gt;-->
              <!--&lt;!&ndash;                                        style="color: #000000;" index="5-3">安全生产类&ndash;&gt;-->
              <!--&lt;!&ndash;                                </el-menu-item>&ndash;&gt;-->
              <!--                            </el-submenu>-->
              <el-submenu class="col-sm-2" index="6"
                          :style="isActive==6?'color:red;background:#ffffff':''">
                <template slot="title">
                  <span :style="isActive==6?'color:red':''">业务办理</span>
                </template>
                <el-menu-item
                    :style="isActiveChilden=='6-1'?'background:#3a6cc1e8;color: white':'background:#ffffff'"
                    style="color: #000000;" index="6-1">安全培训证书
                </el-menu-item>
                <el-menu-item
                    :style="isActiveChilden=='6-2'?'background:#3a6cc1e8;color: white':'background:#ffffff'"
                    style="color: #000000;" index="6-2">在线报名
                </el-menu-item>
              </el-submenu>

            </el-menu>
          </div>
        </nav>

      </div>
    </div>

    <!--       首页-->
    <Index v-if="isActive==1 && isDetail==false" @pathClick="handleSelect"></Index>
    <!--   中心概括-->
    <zxgk v-if="isActive==2 && isDetail==false" :data="isActiveChilden"></zxgk>
    <!--      新闻动态-->
    <xwdt v-if="isActive==3 && isDetail==false" :dataPro="isActiveChilden"></xwdt>
    <!--      业务范围-->
    <ywfw v-if="isActive==4 && isDetail==false" :data="isActiveChilden"></ywfw>
    <!--      政策法规-->
    <zcfg v-if="isActive==5 && isDetail==false" :data="isActiveChilden"></zcfg>
    <!--      业务办理-->
    <ywbl v-if="isActive==6 && isDetail==false" :data="isActiveChilden"></ywbl>
    <detail v-if="isDetail" :id="detailId"></detail>
    <div class="hidden-sm-and-down" style="padding-top: 170px"></div>
    <div class="hidden-sm-and-down home-foot py-1">
      <div class="hidden-sm-and-down home-foot-head py-3 d-flex justify-content-around mx-auto">
        <el-select style="text-align: center!important;" placeholder="----国家部委----"
                   @change="gjbwSelect" v-model="gjbwData">
          <el-option
              style="text-align: center!important;"
              v-for="item in gjbw"
              :key="item.id"
              :label="item.cate"
              :value="item.site_url">
          </el-option>
        </el-select>
        <el-select placeholder="----省政府各部门----" @change="gjbwSelect" v-model="szfgbmData">
          <el-option
              style="text-align: center!important;"
              v-for="item in szfgbm"
              :key="item.id"
              :label="item.cate"
              :value="item.site_url">
          </el-option>
        </el-select>
        <el-select placeholder="----建设行业业务单位----" @change="gjbwSelect" v-model="jshyywdwData">
          <el-option
              style="text-align: center!important;"
              v-for="item in jshyywdw"
              :key="item.id"
              :label="item.cate"
              :value="item.site_url">
          </el-option>
        </el-select>
        <el-select placeholder="----合作企业----" @change="gjbwSelect" v-model="hzqyData">
          <el-option
              style="text-align: center!important;"
              v-for="item in hzqy"
              :key="item.id"
              :label="item.cate"
              :value="item.site_url">
          </el-option>
        </el-select>
      </div>
      <!--      大屏-->
      <el-row class=" home-foot-main mx-auto pt-2">
        <el-col class="text-center pr-2">公司：定西建达职业培训学校有限公司</el-col>
      </el-row>
      <el-row class="home-foot-main mx-auto pt-2">
        <el-col class="text-center pr-2">Copyright © 2020- 2021 甘肃省定西市安定区新城大道小学2幢3楼 All Rights
          Reserved
        </el-col>
      </el-row>
      <el-row class="home-foot-main mx-auto pt-2">
        <el-col :sm="8" :md="8" class="text-center pr-2 cursor-pon"><span
            @click="beianhao">备案号：陇ICP备2020004530号-1</span></el-col>
        <el-col :sm="8" :md="8" class="text-center pr-2">公安备案：<img
            src="@/assets/ga.jpg" alt=""> 甘公网安备
          <span class="cursor-pon" @click="gongan">62110202000251号</span>
        </el-col>
        <el-col :sm="8" :md="8" class="text-center pr-2">咨询热线：18993191111</el-col>
      </el-row>
    </div>
    <div class="hidden-sm-and-up home-foot2 py-1">
      <div class="hidden-sm-and-down home-foot-head py-3 d-flex justify-content-around mx-auto">
      </div>
      <!--      大屏-->
      <div>
        <el-row class=" home-foot-main mx-auto pt-2">
          <el-col class="text-center pr-2 text-white">公司：定西建达职业培训学校有限公司8888</el-col>
        </el-row>
        <el-row class="home-foot-main mx-auto pt-2">
          <el-col class="text-center pr-2 text-white">Copyright © 2020- 2021 甘肃省定西市安定区新城大道小学2幢3楼
            All
            Rights
            Reserved
          </el-col>
        </el-row>
        <el-row class="home-foot-main mx-auto pt-2">
          <el-col :sm="8" :md="8" class="text-center pr-2 text-white cursor-pon"><span @click="beianhao">备案号：陇ICP备2020004530号-1</span>
          </el-col>
          <el-col :sm="8" :md="8" class="text-center pr-2 text-white">公安备案：<img
              src="@/assets/ga.jpg" alt=""> 甘公网安备
            <span class="cursor-pon" @click="gongan">62110202000251号</span>
          </el-col>
          <el-col :sm="8" :md="8" class="text-center pr-2 text-white">咨询热线：18993191111</el-col>
        </el-row>
      </div>
    </div>
    <!--   在线联系-->
    <div class="zxlx">
      <div class="kuang hidden-md-and-down">
        <h5 class="mb-2">在线咨询</h5>
        <!--        <el-button @click="dituClick">地图</el-button>-->
        <el-tooltip class="item" effect="light" placement="left">
          <div slot="content">
            <img style="width: 100px;height: 100px" src="@/assets/qq.png"
                 alt="" srcset="">
          </div>
          <el-button>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor"
                 class="bi bi-tencent-qq"
                 style="color: red" viewBox="0 0 16 16">
              <path
                  d="M6.048 3.323c.022.277-.13.523-.338.55-.21.026-.397-.176-.419-.453-.022-.277.13-.523.338-.55.21-.026.397.176.42.453Zm2.265-.24c-.603-.146-.894.256-.936.333-.027.048-.008.117.037.15.045.035.092.025.119-.003.361-.39.751-.172.829-.129l.011.007c.053.024.147.028.193-.098.023-.063.017-.11-.006-.142-.016-.023-.089-.08-.247-.118Z"/>
              <path fill-rule="evenodd"
                    d="M11.727 6.719c0-.022.01-.375.01-.557 0-3.07-1.45-6.156-5.015-6.156-3.564 0-5.014 3.086-5.014 6.156 0 .182.01.535.01.557l-.72 1.795a25.85 25.85 0 0 0-.534 1.508c-.68 2.187-.46 3.093-.292 3.113.36.044 1.401-1.647 1.401-1.647 0 .979.504 2.256 1.594 3.179-.408.126-.907.319-1.228.556-.29.213-.253.43-.201.518.228.386 3.92.246 4.985.126 1.065.12 4.756.26 4.984-.126.052-.088.088-.305-.2-.518-.322-.237-.822-.43-1.23-.557 1.09-.922 1.594-2.2 1.594-3.178 0 0 1.041 1.69 1.401 1.647.168-.02.388-.926-.292-3.113a25.78 25.78 0 0 0-.534-1.508l-.72-1.795ZM9.773 5.53c-.13-.286-1.431-.605-3.042-.605h-.017c-1.611 0-2.913.319-3.042.605a.096.096 0 0 0-.01.04c0 .022.008.04.018.056.11.159 1.554.943 3.034.943h.017c1.48 0 2.924-.784 3.033-.943a.095.095 0 0 0 .008-.096Zm-4.32-.989c-.483.022-.896-.529-.922-1.229-.026-.7.344-1.286.828-1.308.483-.022.896.529.922 1.23.027.7-.344 1.286-.827 1.307Zm2.538 0c.483.022.896-.529.922-1.229.026-.7-.344-1.286-.827-1.308-.484-.022-.896.529-.923 1.23-.026.7.344 1.285.828 1.307ZM2.928 8.99a10.674 10.674 0 0 0-.097 2.284c.146 2.45 1.6 3.99 3.846 4.012h.091c2.246-.023 3.7-1.562 3.846-4.011.054-.9 0-1.663-.097-2.285-1.312.26-2.669.41-3.786.396h-.017c-.297.003-.611-.005-.937-.023v2.148c-1.106.154-2.21-.068-2.21-.068V9.107a22.93 22.93 0 0 1-.639-.117Z"/>
            </svg>
          </el-button>

        </el-tooltip>
        <el-tooltip class="item" effect="light" placement="left">
          <div slot="content">
            <img style="width: 100px;height: 100px" src="@/assets/weixin.png"
                 alt="" srcset="">
          </div>
          <el-button class="ml-0 mt-3">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" style="color: red"
                 fill="currentColor"
                 class="bi bi-wechat" viewBox="0 0 16 16">
              <path
                  d="M11.176 14.429c-2.665 0-4.826-1.8-4.826-4.018 0-2.22 2.159-4.02 4.824-4.02S16 8.191 16 10.411c0 1.21-.65 2.301-1.666 3.036a.324.324 0 0 0-.12.366l.218.81a.616.616 0 0 1 .029.117.166.166 0 0 1-.162.162.177.177 0 0 1-.092-.03l-1.057-.61a.519.519 0 0 0-.256-.074.509.509 0 0 0-.142.021 5.668 5.668 0 0 1-1.576.22ZM9.064 9.542a.647.647 0 1 0 .557-1 .645.645 0 0 0-.646.647.615.615 0 0 0 .09.353Zm3.232.001a.646.646 0 1 0 .546-1 .645.645 0 0 0-.644.644.627.627 0 0 0 .098.356Z"/>
              <path
                  d="M0 6.826c0 1.455.781 2.765 2.001 3.656a.385.385 0 0 1 .143.439l-.161.6-.1.373a.499.499 0 0 0-.032.14.192.192 0 0 0 .193.193c.039 0 .077-.01.111-.029l1.268-.733a.622.622 0 0 1 .308-.088c.058 0 .116.009.171.025a6.83 6.83 0 0 0 1.625.26 4.45 4.45 0 0 1-.177-1.251c0-2.936 2.785-5.02 5.824-5.02.05 0 .1 0 .15.002C10.587 3.429 8.392 2 5.796 2 2.596 2 0 4.16 0 6.826Zm4.632-1.555a.77.77 0 1 1-1.54 0 .77.77 0 0 1 1.54 0Zm3.875 0a.77.77 0 1 1-1.54 0 .77.77 0 0 1 1.54 0Z"/>
            </svg>
          </el-button>

        </el-tooltip>
      </div>

    </div>
  </div>
</template>

<script>
import Index from '@/components/index.vue'
import gywm from '@/components/zxgk/gywm.vue'
import zxgk from '@/components/zxgk/index.vue'
import pxss from '@/components/zxgk/pxss.vue'
import jgsz from '@/components/zxgk/jgsz.vue'
import xwdt from '@/components/xwdt/index.vue'
import zcfg from '@/components/zcfg/index.vue'
import ywfw from '@/components/ywfw/index.vue'
import ywbl from '@/components/ywbl/index.vue'
import detail from '@/components/detail.vue'
import {getBottomLink, getlbt, login} from "../utils/user";

export default {
  name: "LeftRegionMenu",
  components: {
    Index, gywm, zxgk, pxss, jgsz, xwdt, zcfg, ywfw, ywbl, detail
  },
  props: {
    menuList: {
      // 模拟数据

    }
  },
  data() {
    return {
      isJiHuo: true,
      isDetail: false,
      detailId: null,
      isActive: 1,
      minHeight: 0,
      isActiveChilden: '',
      news: [
        {
          name: "一级菜单2",
          id: "2",
          child: [
            {name: "二级菜单2-1", id: "2-1"},
            {
              name: "二级菜单2-2",
              id: "2-2",
              child: [{name: "三级菜单1", id: "2-2-1"}]
            },
            {
              name: "二级菜单2-3",
              id: "2-3",
              child: [{name: "三级菜单1", id: "2-3-1"}]
            }
          ]
        },
        {
          name: "一级菜单3",
          id: "3",
          child: [{name: "二级菜单3-1", id: "3-1"}]
        },
        {
          name: "一级菜单4",
          id: "4",
          child: [
            {
              name: "二级菜单4-1",
              id: "4-1",
              child: [
                {
                  name: "三级菜单",
                  id: "4-1-1",
                  child: [{name: "四级菜单", id: "4-1-1-1"}]
                },
                {name: "三级菜单", id: "4-1-2"}
              ]
            }
          ]
        }
      ],
      linkModel: '',
      //   页脚数据
      footerLink: [
        {
          value: '选项1',
          label: '111'
        }, {
          value: '选项2',
          label: '222'
        }
      ],
      //     国家部委
      gjbw: [],
      gjbwData: null,
      // 省政府各部门
      szfgbm: [],
      szfgbmData: null,
      // 建设行业业务单位
      jshyywdw: [],
      jshyywdwData: null,
      hzqy: [],
      hzqyData: null,
      curScrollTop: 0 //记录滚动条位置对象

    }
  },
  computed: {
    watchDetail() {
      return this.$store.state.showDetail
    },
    watchDetailId() {
      return this.$store.state.detailId
    }
  },
  watch: {
    watchDetail(newVal, oldVal) {
      this.isDetail = newVal
    },
    watchDetailId(newVal, oldVal) {
      this.detailId = newVal
    }
  },

  mounted() {
    console.log('[返回')
    // window.addEventListener('scroll', this.windowScroll) //监听页面滚动

    this.isDetail = this.$store.state.showDetail
    // 新改
    if (localStorage.getItem('listActive') != null) {
      this.isActive = localStorage.getItem('listActive')
      if (this.isActive == 1) {
        this.isJiHuo = true
      } else {
        this.isJiHuo = false
      }
      if (localStorage.getItem('isListActiveChilden') != null) {
        this.isActiveChilden = localStorage.getItem('isListActiveChilden')
      } else {
        localStorage.setItem("isListActiveChilden", '')
      }
    } else {
      localStorage.setItem("listActive", 1)
      localStorage.setItem("isListActiveChilden", '')

    }


    // 原始
    // if (localStorage.getItem('listActive') != null) {
    //   this.isActive = localStorage.getItem('listActive')
    //   if (this.isActive == 1) {
    //     this.isJiHuo = true
    //   } else {
    //     this.isJiHuo = false
    //   }
    //   if (localStorage.getItem('isListActiveChilden') != null) {
    //     this.isActiveChilden = localStorage.getItem('isListActiveChilden')
    //   } else {
    //     localStorage.setItem("isListActiveChilden", '')
    //   }
    // } else {
    //   localStorage.setItem("listActive", 1)
    //   localStorage.setItem("isListActiveChilden", '')
    //
    // }
    //     获取底部导航栏数据
    this.getBottomNav()
  },
  created() {
    setTimeout(function () {
      localStorage.setItem("listActive", 1)
      localStorage.setItem("isListActiveChilden", '')
    }, 600000)
  },
  methods: {

// 监听页面滚动 获取滚动条位置
    windowScroll() {
      let scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop
      console.log(scrollTop)
      this.$store.commit('setScreenHeight', scrollTop)
    },

    dituClick() {
      this.$router.push({path: '/ditu'})
    },
    gongan() {

      window.open('http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=62110202000251', '_blank')
    },
    beianhao() {
      window.open('https://beian.miit.gov.cn/', '_blank')
    },
    getBottomNav() {
      //     国家部委
      getBottomLink({
        cate: '国家部委'
      }).then(res => {
        this.gjbw = res.data
      })
      //     省政府各部门
      getBottomLink({
        cate: '省政府各部门'
      }).then(res => {
        this.szfgbm = res.data
      })
      // 建设行业业务单位
      getBottomLink({
        cate: '建设行业业务单位'
      }).then(res => {
        this.jshyywdw = res.data
      })
      // 合作企业
      getBottomLink({
        cate: '合作企业'
      }).then(res => {
        this.hzqy = res.data
      })
    },
    visibleChange(e) {
      console.log(e)
    },
    gjbwSelect(e) {
      console.log(e)
      window.location.href = e
    },
    handleSelect(key, keyPath) {
      console.log('====')
      console.log(key)
      console.log(keyPath)
      this.$store.state.showDetail = false
      this.$store.commit('setListActive',keyPath[0])
      this.$store.commit('setIsListActiveChilden',key)
      console.log('储存的值')
      console.log(this.$store.state.listActive)
      console.log(this.$store.state.isListActiveChilden)
      // 默认首页
      if (key == 1) {
        this.isJiHuo = true
      } else {
        this.isJiHuo = false
      }
      this.isActive = keyPath[0]
      this.isActiveChilden = key
      localStorage.setItem("listActive", keyPath[0])
      localStorage.setItem("isListActiveChilden", key)
      //  宽度大于576，就去掉点击事件
      if (window.innerWidth < 576) {
        document.getElementById("zkbtn").click()
      }
      //   证书查询跳转
      // if (this.isActive == 6) {
      //   window.open("https://edu.gsdxjd.com/")
      //
      // }
    },
  },
  destroyed() {
    // window.removeEventListener("scroll", this.windowScroll);//销毁滚动事件
  }
};
// 这部分样式自己按需修改或删除
</script>
<style lang="scss">
.container {
  padding: 0 !important;
}

.home {
  position: relative;
}

::v-deep .is-active {
  background: #0c5460;
}

.navbar {
  padding: 0 !important;
}

::v-deep .is-active {
  background-color: #009195 !important;
}

.home-nav-bg {
  background: url("../assets/b1.jpg") no-repeat;
  //background: url("https://www.gsdxjd.com/uploads/image/20200729/1595992779.jpg") no-repeat;
  height: 200px;
  background-size: cover;
}

.home-nav-main {
  background: url("https://zjt.gansu.gov.cn/zjt/xhtml/images/nav_bg.png");
}

.el-menu--horizontal {
  width: 100%;
}

.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  background: #ffffff !important;
  //background: linear-gradient(135deg, #c7e9fb, #a6defa, #80d4f9, #5bc9f8, #35bef7);;

}

.el-submenu__title {
  font-size: 20px !important;

  &:hover {
    color: #000000 !important;
  }
}


.el-menu-item {
  text-align: center;
  font-size: 20px !important;
}

.el-menu--horizontal > .el-submenu:hover {
  outline: 0;
  color: pink !important;
}

.menu-set-class {
  background: url("https://zjt.gansu.gov.cn/zjt/xhtml/images/nav_bg.png") !important;
}

.menu-set-class > li {
  font-size: 20px !important;

}

//页脚
.home-foot {
  height: 195px;
  padding-top: 25px;

  background-color: #2f5494;

}

.home-foot {
  width: 100%;
  background: #2f5494;
  //position: fixed;
  //bottom: 0;
  //left: 0;
  //z-index: 99;

  .home-foot-head {
    max-width: 1140px;
  }

  .home-foot-main {
    max-width: 1140px;
    background-color: #2f5494;
    color: #ffffff;
    font-size: 14px;
    //text-align: left;
  }
}

.home-foot2 {
  width: 100%;
  background: #2f5494;
  //position: fixed;
  //bottom: 0;
  //left: 0;
  z-index: 9999;

  .home-foot-head {
    max-width: 1140px;
  }

  .home-foot-main {
    max-width: 1140px;
    background-color: #2f5494;
    color: #ffffff;
    font-size: 14px;
    //text-align: left;
  }
}

::v-deep .el-submenu__title i {
  color: #000000;
}

.jihuo {
  background: #ffffff;
}

.no {
  color: #ffffff !important;
  border: none !important;
}

::v-deep .el-menu--horizontal {
  border: none !important;
}

::v-deep .el-menu-item .el-menu-item-new {
  color: red !important;
}

.el-menu-item:hover {
  color: #000000 !important;
}

nav.navbar.navbar-expand-md.bg-dark.navbar-dark {
  background: url("https://zjt.gansu.gov.cn/zjt/xhtml/images/nav_bg.png") !important;
}

::v-deep .el-menu--popup {
  background: pink !important;
}

::v-deep el-menu-item > .is-active > .jihuo_children {
  background: red !important;
}

::v-deep .yes-default-active {
  color: red !important;

}

//::v-deep .el-input__inner {
//    text-align: center!important;
//  padding-left: 10px!important;
//}
.zxlx {
  position: fixed;
  right: 20px;
  bottom: 25%;

  .kuang {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    .erweima {
      position: absolute;
      display: none;
      right: 30px;
      bottom: -180%;
      width: 200px;
      height: 200px;
      background: pink;
    }

    .erweima2 {
      position: absolute;
      display: none;
      right: 30px;
      bottom: -180%;
      width: 200px;
      height: 200px;
      background: pink;
    }

    .qq {
      margin-bottom: 20px;
    }

    &:hover {
      .erweima {
        display: block;
      }
    }

    .weixin {

    }

    &:hover {
      .erweima2 {
        display: block;
      }
    }

  }
}

::v-deep .el-tooltip__popper {
  border: none !important;
}

::v-deep .el-select-dropdown_item {
  padding-left: 20px !important;
  box-sizing: border-box !important;
  text-align: center !important;
}

::v-deep .popper_arrow {
  left: 0 !important;
}
</style>

