<!--<template>-->
<!-- <div class="container">-->
<!--   &lt;!&ndash; 百度地图  &ndash;&gt;-->
<!--   <div id="bai-du-map" style="width: 100%;height: 500px">-->
<!--     &lt;!&ndash; 技术支持和联系方式  &ndash;&gt;-->

<!--   </div>-->
<!-- </div>-->
<!--</template>-->

<!--<script>-->
<!--import AMapLoader from "@amap/amap-jsapi-loader";-->

<!--// window._AMapSecurityConfig = {-->
<!--//   // 设置安全密钥-->
<!--//   securityJsCode: '635112e60a7a4097cef6424b7fa7e17d',-->
<!--// }-->
<!--export default {-->
<!--  data() {-->
<!--    return {-->
<!--      map: null,-->
<!--      mouseTool: null,-->
<!--      overlays: [],-->
<!--      auto: null,-->
<!--      placeSearch: null,-->
<!--    }-->
<!--  },-->
<!--  methods: {-->
<!--    initMap() {-->
<!--      AMapLoader.load({-->
<!--        "key": "635112e60a7a4097cef6424b7fa7e17d", // 申请好的Web端开发者Key，首次调用 load 时必填-->
<!--        "version": "2.0",   // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15-->
<!--        "plugins": ["AMap.Scale", "AMap.ToolBar", "AMap.AutoComplete", "AMap.PlaceSearch", "AMap.ControlBar", "AMap.MouseTool"],           // 需要使用的的插件列表，如比例尺'AMap.Scale'等-->
<!--        // "plugins": [],           // 需要使用的的插件列表，如比例尺'AMap.Scale'等-->
<!--      }).then((AMap) => {-->
<!--        this.map = new AMap.Map('bai-du-map', {-->
<!--          viewMode: "2D",  //  是否为3D地图模式-->
<!--          zoom: 13,   // 初始化地图级别-->
<!--          center: [104.608604,35.607173], //中心点坐标  郑州-->
<!--          resizeEnable: true-->
<!--        });-->
<!--        this.mouseTool = new AMap.MouseTool(this.map);-->
<!--        // 监听draw事件可获取画好的覆盖物-->
<!--        this.mouseTool.on('draw', function (e) {-->
<!--          this.overlays.push(e.obj);-->
<!--        }.bind(this))-->
<!--      }).catch(e => {-->
<!--        console.log(e);-->
<!--      });-->
<!--    },-->
<!--    draw(type) {-->
<!--      switch (type) {-->
<!--        case 'marker': {-->
<!--          this.mouseTool.marker({-->
<!--            //同Marker的Option设置-->
<!--            content:'88888'-->
<!--          });-->
<!--          break;-->
<!--        }-->
<!--        case 'polyline': {-->
<!--          this.mouseTool.polyline({-->
<!--            strokeColor: '#80d8ff'-->
<!--            //同Polyline的Option设置-->
<!--          });-->
<!--          break;-->
<!--        }-->
<!--        case 'polygon': {-->
<!--          this.mouseTool.polygon({-->
<!--            fillColor: '#00b0ff',-->
<!--            strokeColor: '#80d8ff'-->
<!--            //同Polygon的Option设置-->
<!--          });-->
<!--          break;-->
<!--        }-->
<!--        case 'rectangle': {-->
<!--          this.mouseTool.rectangle({-->
<!--            fillColor: '#00b0ff',-->
<!--            strokeColor: '#80d8ff'-->
<!--            //同Polygon的Option设置-->
<!--          });-->
<!--          break;-->
<!--        }-->
<!--        case 'circle': {-->
<!--          this.mouseTool.circle({-->
<!--            fillColor: '#00b0ff',-->
<!--            strokeColor: '#80d8ff'-->
<!--            //同Circle的Option设置-->
<!--          });-->
<!--          break;-->
<!--        }-->
<!--      }-->

<!--    },-->
<!--  },-->
<!--  mounted() {-->
<!--    this.initMap();-->
<!--  },-->
<!--};-->
<!--</script>-->
<template>
  <div class="map">
    <div style="width: 100%; height: 500px" id="allmap" ref="allmap"></div>
    <!--    <amap></amap>-->
  </div>

</template>

<script>
export default {
  name: "ditu",
  data(){
    return {

    }
  },
  mounted() {
    var map = new BMapGL.Map(this.$refs.allmap); // 创建地图实例
    var point = new BMapGL.Point(104.608604,35.607173); // 创建点坐标
    map.centerAndZoom(point, 15); // 初始化地图，设置中心点坐标和地图级别
    map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放
    map.setHeading(64.5); //设置地图旋转角度
    map.setTilt(40); //设置地图的倾斜角度
    var scaleCtrl = new BMapGL.ScaleControl(); // 添加比例尺控件
    map.addControl(scaleCtrl);
    //创建地址解析器实例
    var myGeo = new BMapGL.Geocoder();
    // 将地址解析结果显示在地图上，并调整地图视野
    myGeo.getPoint(
        "定西市安定区安定路大地幼儿园西南侧",
        function (point) {
          if (point) {
            map.centerAndZoom(point, 16);
            map.addOverlay(
                new BMapGL.Marker(point, {
                  title: "定西建达职业培训学校有限公司",
                })
            );
          } else {
            alert("您选择的地址没有解析到结果！");
          }
        },
        "北京市"
    );
  },

}
</script>

<style scoped>

</style>
