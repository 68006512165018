import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    showDetail:false,
    detailId:null,
    screenHeight:null,
    listActive:null,
    isListActiveChilden:null
  },
  mutations: {
    setShowDetail(state,data){
      state.showDetail=data
    },
    setDetailId(state,data){
      state.detailId=data
    },
    setListActive(state,data){
      state.listActive=data
    },
    setIsListActiveChilden(state,data){
      state.isListActiveChilden=data
    }
  },
  actions: {
  },
  modules: {
  }
})
